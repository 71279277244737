import { of, concat } from 'rxjs';
import {
  map, switchMap, catchError, mergeMap,
} from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { createAction } from 'redux-actions';
import { push, goBack } from 'connected-react-router';
import {
  getAjaxJsonHeader,
  setDeviceTokenForRequiredTypes,
  passwordRecoveryUrlCheck,
} from '../../../../util/misc/common';
import { types } from '..';
import { getErrorMessage, sendingAsyncRequest } from '../../../helpers/epics';
import { constants } from '../change-password-constants';
import { getUrlLocationSearch } from '../../../../util/misc';
import { HOME, CHANGE_PASSWORD } from '../../../../routes/router-url-constants';
import { types as authType } from '../../login/login-actions';
import { types as tcType } from '../../terms-and-conditions/terms-and-conditions-actions';
import { types as stepUpType } from '../../step-up/step-up-actions';
import { types as questionCollectionType } from '../../question-collection';

const {
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAIL,
  CHANGE_PASSWORD_CANCEL,
  CHANGE_PASSWORD_REMIND_ME_LATER_REQUEST,
  CHANGE_PASSWORD_REMIND_ME_LATER_SUCCESS,
  CHANGE_PASSWORD_REMIND_ME_LATER_FAIL,
  FLOW_COMPLETE,
  DISPLAY_CHANGE_PASSWORD,
  CURRENT_PASSWORD_ERROR,
} = types;
const { AUTHENTICATE_SUCCESS, RESET_STATE } = authType;
const { DISPLAY_TERMS_AND_CONDITIONS } = tcType;
const { DISPLAY_CHANNELS } = stepUpType;
const { DISPLAY_QUESTION_COLLECTION } = questionCollectionType;
const { CHANGE_PASSWORD_ENDPOINT } = constants;
const QUESTION_COLLECTION_CHALLENGE = 'QUESTION_COLLECTION_CHALLENGE';

const changePasswordSuccess = createAction(CHANGE_PASSWORD_SUCCESS);
const changePasswordFail = createAction(CHANGE_PASSWORD_FAIL);
const currentPasswordError = createAction(CURRENT_PASSWORD_ERROR);
const changePasswordRemindMeLaterSuccess = createAction(CHANGE_PASSWORD_REMIND_ME_LATER_SUCCESS);
const changePasswordRemindMeLaterFail = createAction(CHANGE_PASSWORD_REMIND_ME_LATER_FAIL);
const flowComplete = createAction(FLOW_COMPLETE);
const authenticateSuccess = createAction(AUTHENTICATE_SUCCESS);
const displayQuestionCollection = createAction(DISPLAY_QUESTION_COLLECTION);

const tcDisplay = createAction(DISPLAY_TERMS_AND_CONDITIONS);
const displayChannels = createAction(DISPLAY_CHANNELS);
const resetState = createAction(RESET_STATE);

const handleResponse = (responseObj, isAppView) => {
  setDeviceTokenForRequiredTypes(responseObj);

  const { type: passwordUpdateStatus, reason } = responseObj.response.result || '';
  const accountAboutToLock = responseObj.response.challenge || '';

  let passwordRecoveryUrl = responseObj.response.challenge || '';

  passwordRecoveryUrl =
    passwordRecoveryUrl === '' || passwordRecoveryUrl === undefined ?
      passwordRecoveryUrlCheck(passwordRecoveryUrl) :
      passwordRecoveryUrl;

  const { session: changePasswordDisplaySession } = responseObj.response;

  if (passwordUpdateStatus !== 'PASSWORD_CHANGED') {
    if (!isAppView && reason === 'INVALID_CURRENT_PASSWORD') {
      const currentPwdCheck = 'error';

      return currentPasswordError({
        currentPwdCheck,
      });
    }

    return changePasswordFail({
      passwordUpdateStatus,
      reason,
      accountAboutToLock,
      changePasswordDisplaySession,
    });
  }

  return changePasswordSuccess({
    passwordUpdateStatus,
    reason,
    passwordRecoveryUrl,
    accountAboutToLock,
    changePasswordDisplaySession,
  });
};

const submitChangePasswordRequest = (
  currentPassword,
  updatedPassword,
  changePasswordDisplaySession,
) => ({
  response: {
    type: 'PASSWORD_CHANGE_RESPONSE',
    skip: false,
    oldPassword: currentPassword,
    newPassword: updatedPassword,
  },
  session: changePasswordDisplaySession,
});

const changePasswordRemindMeLaterRequest = changePasswordDisplaySession => ({
  response: {
    type: 'PASSWORD_CHANGE_RESPONSE',
    skip: true,
  },
  session: changePasswordDisplaySession,
});

const changePasswordService = (
  ajax,
  userid,
  isAppView,
  currentPassword,
  newPassword,
  changePasswordDisplaySession,
  changePasswordActionUrl,
) => ajax({
  url: changePasswordActionUrl || `${CHANGE_PASSWORD_ENDPOINT}`,
  headers: getAjaxJsonHeader(),
  method: 'POST',
  responseType: 'json',
  withCredentials: true,
  body: JSON.stringify(
    submitChangePasswordRequest(currentPassword, newPassword, changePasswordDisplaySession),
  ),
}).pipe(
  map(response => handleResponse(response, isAppView)),
  catchError(err => of(changePasswordFail(getErrorMessage(err)))),
);

// eslint-disable-next-line max-len
const changePasswordRemindMeLaterService = (
  ajax,
  changePasswordDisplaySession,
  changePasswordActionUrl,
) => ajax({
  url: changePasswordActionUrl || `${CHANGE_PASSWORD_ENDPOINT}`,
  headers: getAjaxJsonHeader(),
  method: 'POST',
  responseType: 'json',
  withCredentials: true,
  body: JSON.stringify(changePasswordRemindMeLaterRequest(changePasswordDisplaySession)),
}).pipe(
  map(response => handleRemindMeLaterResponse(response)),
  catchError(err => of(changePasswordRemindMeLaterFail(getErrorMessage(err)))),
);

const handleRemindMeLaterResponse = (responseObj) => {
  const { accessToken, redirectUrl } = responseObj.response.result || '';
  const { session, challenge } = responseObj.response;

  setDeviceTokenForRequiredTypes(responseObj);

  if (challenge && challenge.type === QUESTION_COLLECTION_CHALLENGE) {
    return displayQuestionCollection({
      questionCollectionDisplaySession: session,
      questionCollectionDisplayChallenge: challenge,
      passwordStatus: '',
    });
  }

  if (accessToken) {
    return authenticateSuccess({
      accessToken,
      redirectUrl,
    });
  }
  return changePasswordRemindMeLaterSuccess({
    challenge,
    session,
  });
};

// prettier-ignore
export const changePasswordEpic = (action$, state$, { ajax }) => action$.pipe(
  ofType(CHANGE_PASSWORD_REQUEST),
  switchMap(() => {
    const { userid } = state$.value.login;
    const { isAppView } = state$.value.signin;
    const {
      currentPassword, newPassword, changePasswordDisplaySession, changePasswordActionUrl,
    } = state$.value.changepassword;

    return sendingAsyncRequest(
    // eslint-disable-next-line max-len
      changePasswordService(ajax, userid, isAppView, currentPassword, newPassword, changePasswordDisplaySession, changePasswordActionUrl),
    );
  }),
);

const handleInternalUrlRedirect = (response) => {
  const { passwordUpdateStatus } = response;

  let url = '';

  if (passwordUpdateStatus === 'PASSWORD_CHANGED') {
    url = HOME + getUrlLocationSearch();
  }
  return url;
};

export const changePasswordSuccessEpic = action$ => action$.pipe(
  ofType(CHANGE_PASSWORD_SUCCESS),
  map(({ payload: response }) => handleInternalUrlRedirect(response)),
  switchMap((url) => {
    if (url) {
      return of(push(url));
    }
    return of(flowComplete());
  }),
);

export const changePasswordCancelEpic = action$ => action$.pipe(
  ofType(CHANGE_PASSWORD_CANCEL),
  switchMap(() => of(goBack(HOME + getUrlLocationSearch()))),
);

// prettier-ignore
export const changePasswordRemindMeLaterEpic = (action$, state$, { ajax }) => action$.pipe(
  ofType(CHANGE_PASSWORD_REMIND_ME_LATER_REQUEST),
  switchMap(() => {
    const { changePasswordActionUrl, changePasswordDisplaySession } = state$.value.changepassword;

    return sendingAsyncRequest(
    // eslint-disable-next-line max-len
      changePasswordRemindMeLaterService(ajax, changePasswordDisplaySession, changePasswordActionUrl),
    );
  }),
);

export const changePasswordRemindMeLaterSuccessEpic = action$ => action$.pipe(
  ofType(CHANGE_PASSWORD_REMIND_ME_LATER_SUCCESS),
  // eslint-disable-next-line max-len
  mergeMap(({ payload: { challenge, session } }) => concat(of(resetState()), of(handleRedirectForRemindeMeLater(challenge, session)))),
);

const handleRedirectForRemindeMeLater = (challenge, session) => {
  if (challenge && challenge.type === 'CONSENT_COLLECTION_CHALLENGE') {
    return tcDisplay({
      tcSession: session,
      tcChallenge: challenge,
    });
  }
  if (challenge && challenge.authenticators) {
    return displayChannels({
      channelsDisplaySession: session,
      channelsDisplayChallenge: challenge,
    });
  }
  return changePasswordRemindMeLaterFail({
    error: 'Application Error. Please try again later',
  });
};

export const displayChangePasswordEpic = action$ => action$.pipe(
  ofType(DISPLAY_CHANGE_PASSWORD),
  switchMap(() => of(push(CHANGE_PASSWORD + getUrlLocationSearch()))),
);
