/* eslint-disable no-undef */
import { of } from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { createAction } from 'redux-actions';
import { isEmpty } from 'lodash';
import {
  getAjaxJsonHeader,
  getProductId,
  getOrganizationId,
  getPlatformAuthenticatorService,
  initProfiling,
  handlePendoTracking,
  generateSessionID,
  getCallingAppId,
  getTransactionId,
  getCookieCacheToken,
  isApp,
  setCookieCacheInfo,
  getTargetUrl,
  DEFAULT_TARGETURL,
} from '../../../../util/misc';
import { types } from '..';
import { getErrorMessage } from '../../../helpers/epics';
import { constants } from '../signin-constants';

const {
  LOAD_ACCOUNTTYPE_REQUEST,
  LOAD_ACCOUNTTYPE_SUCCESS,
  LOAD_ACCOUNTTYPE_FAIL,
  LOAD_PLATFORMAUTHETICATOR_REQUEST,
} = types;
const { SIGNIN_START_ENDPOINT } = constants;

const loadPlatformAuthenticatorSuccess = createAction(LOAD_ACCOUNTTYPE_REQUEST);
const loadPlatformAuthenticatorFail = createAction(LOAD_ACCOUNTTYPE_REQUEST);
const loadAccountTypeSuccess = createAction(LOAD_ACCOUNTTYPE_SUCCESS);
const loadAccountTypeFail = createAction(LOAD_ACCOUNTTYPE_FAIL);

const productId = getProductId();

const organizationId = getOrganizationId();

const callingAppId = getCallingAppId().toUpperCase();

const transactionId = getTransactionId() || undefined;

const cookieCacheToken = isApp() ? getCookieCacheToken() || undefined : undefined;

let fidoSupported = false;

let riskSessionId;

const docTitle = `${document.getElementsByTagName('title')[0].text} ${callingAppId}`;

document.title = docTitle;

const targetUrl = getTargetUrl();

const targetRequired = isEmpty(targetUrl) || targetUrl === DEFAULT_TARGETURL ? true : undefined;

const callLoadAccountTypeService = (ajax, isPlatformAuthenticatorAvailable) => ajax({
  url: `${SIGNIN_START_ENDPOINT}`,
  headers: { ...getAjaxJsonHeader(), ...{ UVPAA: isPlatformAuthenticatorAvailable } },
  method: 'POST',
  responseType: 'json',
  /* Hardcoding prod and org id for now till prod level implementation is completed */
  body: JSON.stringify({
    productId,
    organizationId,
    mfTransactionId: transactionId,
    cookieCacheToken,
    targetRequired,
  }),
}).pipe(
  map(response => handleResponse(response)),
  catchError(err => of(loadAccountTypeFail(getErrorMessage(err)))),
);

export const loadPlatformAuthenticatorEpic = (action$, _, { ajax }) => action$.pipe(
  ofType(LOAD_PLATFORMAUTHETICATOR_REQUEST),
  switchMap(() => getPlatformAuthenticatorService()),
  map(response => hadlePlatformAuthenticatorResponse({ response })),
  // eslint-disable-next-line max-len
  // prettier-ignore
);

export const loadAccountTypeEpic = (action$, _, { ajax }) => action$.pipe(
  ofType(LOAD_ACCOUNTTYPE_REQUEST),
  // eslint-disable-next-line max-len
  switchMap(({ payload: booleanResponse }) => callLoadAccountTypeService(ajax, booleanResponse)),
  // prettier-ignore
);

const hadlePlatformAuthenticatorResponse = (response) => {
  try {
    fidoSupported = response.response;

    return loadPlatformAuthenticatorSuccess(fidoSupported);
  } catch (error) {
    return loadPlatformAuthenticatorFail(false);
  }
};

const handleResponse = (response) => {
  const { resources } = response.response.resourcePrefetching || [];
  const random = Math.floor(Math.random() * 100 + 1);

  const cookieCacheInfo = response.response.cookieCacheInfo || {};

  if (
    window.env &&
    (window.env.DISABLE_LN_TMSCRIPT === 'false' || window.env.DISABLE_LN_TMSCRIPT === false) &&
    window.env.ORG_ID_ENV &&
    window.env.LNSCRIPT_PROFILING_DOMAIN &&
    window.env.LNSCRIPT_PAGE_ID &&
    ((window.env.DEVICE_PROFILE_APPLIST !== undefined &&
      window.env.DEVICE_PROFILE_APPLIST.indexOf(callingAppId) !== -1) ||
      window.env.DEVICE_PROFILE_APPLIST === undefined)
  ) {
    riskSessionId = generateSessionID();
    (async () => {
      initProfiling(
        window.env.LNSCRIPT_PROFILING_DOMAIN,
        window.env.ORG_ID_ENV,
        window.env.LNSCRIPT_PAGE_ID,
        riskSessionId,
      );
    })();
  }

  if (isApp() && !isEmpty(getCookieCacheToken()) && !isEmpty(cookieCacheInfo)) {
    setCookieCacheInfo(cookieCacheInfo);
  }

  handlePendoTracking();

  if (resources) {
    resources.forEach((resource) => {
      try {
        loadScript(`${resource.url}?${random}`);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Could not load JS from url', error);
      }
    });
  }

  return loadAccountTypeSuccess({
    response,
    fidoSupported,
    riskSessionId,
    targetUrl,
    targetRequired,
  });
};

const loadScript = (src) => {
  const script = document.createElement('script');

  script.async = true;
  script.src = src;
  document.body.appendChild(script);
};
